<template>
  <v-container fluid class="my-3 mx-0 pa-0">
    <p class="mt-5 mb-8 mx-2" style="font-size: 20px">
      {{ $t("history.elements.files.files") }}
    </p>
    <v-card
      class="pa-5 d-flex flex-wrap align-content-start"
      elevation="0"
      min-height="50vh"
    >
      <div
        v-for="(folder, folderName) in localFolders"
        :key="folderName"
        class="folder-container"
        @click="openFolder(folderName)"
      >
        <v-img
          max-height="40"
          max-width="50"
          src="@/assets/projectfullview/folder.svg"
          class="my-2"
        ></v-img>
        <p class="mb-0">{{ capitalizeFirstLetter(folderName) }}</p>
        <p class="text-body-2 color-disabled">
          {{ folder.length }} {{ $t("history.elements.files.files") }}
        </p>
      </div>
      <div class="folder-container" @click="dialogFolder = true">
        <v-img
          max-height="40"
          max-width="50"
          src="@/assets/projectfullview/folderyellow.svg"
          class="my-2"
        ></v-img>
        <p class="mb-0">{{ $t("history.elements.files.addNew") }}</p>
        <p>{{ $t("history.elements.files.folder") }}</p>
      </div>
    </v-card>

    <v-alert
      style="max-width: 40%"
      class="mt-5"
      v-if="showAlert"
      type="success"
      dismissible
    >
      {{ alertMessage }}
    </v-alert>

    <!-- Create new Folder -->
    <v-dialog v-model="dialogFolder" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("history.elements.files.addNewFolder")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newFolderName"
            :label="$t('history.elements.files.folderName')"
            required
            outlined
          ></v-text-field>
          <v-alert v-if="creatingFolderErrorMessages" type="error" dense text>{{
            creatingFolderErrorMessages
          }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="creatingFolder"
            text
            @click="dialogFolder = false"
            >{{ $t("history.elements.files.cancel") }}</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :disabled="creatingFolder"
            @click="addFolder"
            >{{ $t("history.elements.files.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End create new folder -->

    <!-- Show Folder Contents -->
    <v-dialog v-model="dialogFolderContents" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedFolderName }}</span>
        </v-card-title>

        <v-card-text class="d-flex flex-wrap align-content-start">
          <div
            v-for="(document, index) in localFolders[selectedFolderName]"
            :key="index"
            class="folder-container"
            @click="openPdf(document.path)"
            style="position: relative"
          >
            <v-img
              max-height="60"
              max-width="60"
              :src="getImage(document.path)"
              class="my-2"
            ></v-img>
            <p class="mb-0">{{ document.name }}</p>
            <v-btn
              icon
              class="context-menu-btn"
              @click.stop="showContextMenu($event, document, index)"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
          <!-- Upload Document Button -->
          <div class="folder-container" @click="dialogUploadDocument = true">
            <v-icon size="70">mdi-cloud-upload-outline</v-icon>
            <p class="mb-0">{{ $t("history.elements.files.upload") }}</p>
          </div>
        </v-card-text>

        <v-alert
          style="max-width: 90%"
          class="mt-2 ml-5"
          v-if="cleaningDocumentErrorMessages"
          type="error"
          dismissible
        >
          {{ cleaningDocumentErrorMessages }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogFolderContents = false"
            >{{ $t("history.elements.files.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End show folder contents -->

    <!-- Upload Document -->
    <v-dialog v-model="dialogUploadDocument" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("history.elements.files.uploadDocuments")
          }}</span>
        </v-card-title>

        <v-card-text class="d-flex flex-wrap align-content-start">
          <v-file-input
            show-size
            v-model="selectedFiles"
            :rules="[
              (v) =>
                !v ||
                v.length <= numberOfFiles ||
                $t('history.elements.files.uploadLimit'),
              (v) =>
                !v ||
                v.every((file) => file.size <= weightOfFiles) ||
                $t('history.elements.files.fileSizeLimit'),
            ]"
            multiple
            :label="$t('history.elements.files.selectDocuments')"
          ></v-file-input>
          <v-alert v-if="uploadDocumentErrorMessages" type="error" dense text>{{
            uploadDocumentErrorMessages
          }}</v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="uploadingDocument"
            color="blue darken-1"
            text
            @click="dialogUploadDocument = false"
            >{{ $t("history.elements.files.cancel") }}</v-btn
          >
          <v-btn
            :disabled="uploadingDocument"
            color="blue darken-1"
            text
            @click="uploadDocument"
            >{{ $t("history.elements.files.upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End upload document -->

    <!-- Context Menu -->
    <v-menu
      v-model="contextMenuVisible"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      transition="scale-transition"
    >
      <v-list>
        <v-list-item @click="deleteDocument(selectedDocument.document)">
          <v-list-item-title>{{
            $t("history.elements.files.delete")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="showEditDocument(selectedDocument.document)">
          <v-list-item-title>{{
            $t("history.elements.files.edit")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- End context menu -->

    <!-- End Edit name document -->
    <v-dialog v-model="dialogEditDocument" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("history.elements.files.editDocument")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="currentNameDocument"
            :label="$t('history.elements.files.documentName')"
            :rules="[(v) => !!v || $t('history.elements.files.requiredName')]"
            outlined
          ></v-text-field>
          <v-alert v-if="editNameErrorMessages" type="error" dense text>{{
            editNameErrorMessages
          }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="editingName"
            text
            @click="dialogEditDocument = false"
            >{{ $t("history.elements.files.cancel") }}</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :disabled="editingName"
            @click="editDocument"
            >{{ $t("history.elements.files.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End edit name document -->
  </v-container>
</template>

<script>
import project from "@/api/project";
import { mapMutations } from "vuex";
export default {
  name: "Files",
  props: {
    folders: Object,
  },
  data() {
    return {
      localFolders: {},
      dialogFolder: false,
      newFolderName: "",
      dialogFolderContents: false,
      selectedFolderName: "",
      dialogUploadDocument: false,
      selectedFiles: [],
      creatingFolder: false,
      creatingFolderErrorMessages: "",
      uploadingDocument: false,
      uploadDocumentErrorMessages: "",
      showAlert: false,
      alertMessage: "",
      numberOfFiles: parseInt(process.env.VUE_APP_NUMBER_OF_FILES),
      weightOfFiles:
        parseInt(process.env.VUE_APP_WEIGHT_OF_FILES) * 1024 * 1024,
      contextMenuVisible: false,
      contextMenu: { x: 0, y: 0 },
      selectedDocument: null,
      cleaningDocumentErrorMessages: "",
      currentDocument: {},
      currentNameDocument: "",
      dialogEditDocument: false,
      editingName: false,
      editNameErrorMessages: "",
    };
  },
  methods: {
    ...mapMutations(["setModalDocuments"]),
    openPdf(path) {
      this.setModalDocuments(path);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showAlertMessage(message) {
      this.alertMessage = message;
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
    async addFolder() {
      if (this.newFolderName) {
        this.creatingFolder = true;
        this.creatingFolderErrorMessages = "";
        let formData = new FormData();
        formData.append("folder_name", this.newFolderName);
        formData.append("project_id", this.$route.params.id);
        try {
          const response = await project.createFolder(formData);
          if (response.code == 200) {
            this.localFolders[this.newFolderName] = [];
            this.creatingFolder = false;
            this.dialogFolder = false;
            this.newFolderName = "";
            this.showAlertMessage(
              this.$t("history.elements.files.folderCreated"),
            );
          } else if (response.code == 400) {
            this.creatingFolderErrorMessages = response.message;
            this.creatingFolder = false;
          } else {
            this.creatingFolderErrorMessages = this.$t(
              "history.elements.files.errorOccurred",
            );
            this.creatingFolder = false;
          }
        } catch (error) {
          this.creatingFolderErrorMessages = this.$t(
            "history.elements.files.errorOccurred",
          );
          this.creatingFolder = false;
        }
      }
    },
    openFolder(folderName) {
      this.selectedFolderName = folderName;
      this.dialogFolderContents = true;
    },
    async uploadDocument() {
      if (this.selectedFiles.length > 0) {
        if (this.selectedFiles.length > this.numberOfFiles) {
          this.uploadDocumentErrorMessages = this.$t(
            "history.elements.files.uploadLimit",
          );
          return;
        }

        if (
          !this.selectedFiles.every((file) => file.size <= this.weightOfFiles)
        ) {
          this.uploadDocumentErrorMessages = this.$t(
            "history.elements.files.fileSizeLimit",
          );
          return;
        }

        this.uploadingDocument = true;
        this.uploadDocumentErrorMessages = "";
        let formData = new FormData();

        this.selectedFiles.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        formData.append("folder_name", this.selectedFolderName);
        formData.append("project_id", this.$route.params.id);

        try {
          const response = await project.createFolder(formData);
          if (response.code == 200) {
            this.uploadingDocument = false;
            this.selectedFiles = [];
            this.$emit("file-uploaded");
            this.dialogUploadDocument = false;
            this.dialogFolderContents = false;
            this.showAlertMessage(
              this.$t("history.elements.files.documentUploaded"),
            );
          } else if (response.code == 400) {
            this.uploadDocumentErrorMessages = response.message;
            this.uploadingDocument = false;
          } else {
            this.uploadDocumentErrorMessages = this.$t(
              "history.elements.files.errorOccurred",
            );
            this.uploadingDocument = false;
          }
        } catch (error) {
          this.uploadDocumentErrorMessages = this.$t(
            "history.elements.files.errorOccurred",
          );
          this.uploadingDocument = false;
        }
      }
    },
    getImage(path) {
      const type = path.split(".")[path.split(".").length - 1];
      if (["doc", "docx"].includes(type)) {
        return require("@/assets/projectfullview/odt.svg");
      } else if (type == "pdf") {
        return require("@/assets/projectfullview/pdf.svg");
      } else if (["jpg", "jpeg", "png", "gif", "heic"].includes(type)) {
        return `https://nva-storage.nyc3.cdn.digitaloceanspaces.com/${path}`;
      } else {
        return require("@/assets/projectfullview/document.svg");
      }
    },
    showContextMenu(event, document, index) {
      this.contextMenu.x = event.clientX;
      this.contextMenu.y = event.clientY;
      this.contextMenuVisible = true;
      this.selectedDocument = { document, index };
    },
    async deleteDocument(document) {
      this.cleaningDocumentErrorMessages = "";
      this.contextMenuVisible = false;

      const data = {
        file_id: document.identifier,
      };

      try {
        const response = await project.deleteFile(data);
        if (response.code == 200) {
          this.$emit("file-uploaded");
          this.showAlertMessage(
            this.$t("history.elements.files.documentDeleted"),
          );
        } else if (response.code == 400) {
          this.cleaningDocumentErrorMessages = response.message;
        } else {
          this.cleaningDocumentErrorMessages = this.$t(
            "history.elements.files.errorOccurred",
          );
        }
      } catch (error) {
        this.cleaningDocumentErrorMessages = this.$t(
          "history.elements.files.errorOccurred",
        );
      }
    },
    showEditDocument(document) {
      this.currentDocument = document;
      this.currentNameDocument = document.name;
      this.dialogEditDocument = true;
    },
    async editDocument() {
      if (this.currentNameDocument && this.currentNameDocument.length > 0) {
        this.editingName = true;
        this.editNameErrorMessages = "";
        const data = {
          file_id: this.currentDocument.identifier,
          filename: this.currentNameDocument,
        };

        try {
          const response = await project.editNameFile(data);
          if (response.code == 200) {
            this.editingName = false;
            this.dialogEditDocument = false;
            this.$emit("file-uploaded");
            this.showAlertMessage(
              this.$t("history.elements.files.documentEdited"),
            );
          } else if (response.code == 400) {
            this.editNameErrorMessages = response.message;
            this.editingName = false;
          } else {
            this.editNameErrorMessages = this.$t(
              "history.elements.files.errorOccurred",
            );
            this.editingName = false;
          }
        } catch (error) {
          this.editNameErrorMessages = this.$t(
            "history.elements.files.errorOccurred",
          );
          this.editingName = false;
        }
      }
    },
  },
  watch: {
    folders: {
      immediate: true,
      handler(newValue) {
        this.localFolders = JSON.parse(JSON.stringify(newValue));
      },
    },
  },
};
</script>

<style scoped>
.folder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #b5c0cc;
  border-radius: 11px;
  height: 130px;
  width: 130px;
  margin: 0.5rem;
  cursor: pointer;
}

.folder-container:hover {
  background-color: #f5f5f5;
}

.folder-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  text-align: center;
}

.color-disabled {
  color: #2b2c30;
}

.context-menu-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
