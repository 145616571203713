<template>
  <v-dialog
    v-model="localDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    style="z-index: 1002"
  >
    <v-card>
      <v-content
        fluid
        class="d-flex flex-column justify-start align-center pa-0 ma-0"
      >
        <header class="d-flex flex-column mt-5 mb-2">
          <p class="text-center font-weight-regular mb-15 mt-5">
            New Damage Scope
          </p>
          <p class="text-center font-weight-light text-h4">
            Add Trades To {{ structure.structure_name }}
          </p>
          <p class="mb-2 text-center mb-2">
            Select all trades that apply to this structure
          </p>
        </header>
        <div
          v-if="structure.trades && structure.trades.length > 0"
          class="d-flex flex-column justify-center align-center"
        >
          <p class="text-center font-weight-light text-h4 mt-10">
            The structure {{ structure.structure_name }} is already complete.
          </p>
          <v-btn
            @click="closeDialog"
            elevation="0"
            class="edit-button mt-10 back-button"
            >Back To structures</v-btn
          >
        </div>
        <v-stepper v-else v-model="step" elevation="0" class="step-content">
          <v-stepper-header style="box-shadow: none">
            <v-stepper-step :complete="step > 1" step="1"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row class="mb-5">
                <v-col v-for="(card, index) in cards" :key="index" cols="4">
                  <v-card
                    elevation="0"
                    @click="toggleCardSelection(index)"
                    :style="
                      card.selected
                        ? 'background-color: #EEF2FD;'
                        : 'background-color: white;'
                    "
                  >
                    <v-img
                      max-height="70px"
                      max-width="70px"
                      :src="require(`@/assets/cards/scope/${card.image}`)"
                      class="mb-0"
                    />
                    <p>{{ card.name }}</p>
                  </v-card>
                </v-col>
              </v-row>

              <div
                class="d-flex justify-center mb-5"
                style="width: 100%; gap: 0.5rem"
              >
                <v-btn
                  @click="closeDialog"
                  elevation="0"
                  class="edit-button mt-10 back-button"
                  >Back To structures</v-btn
                >
                <v-btn
                  @click="step = 2"
                  elevation="0"
                  :disabled="!selectedCards.length"
                  class="edit-button mt-10"
                  >Next</v-btn
                >
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row class="mb-5">
                <v-col
                  v-for="(card, index) in selectedCards"
                  :key="index"
                  cols="4"
                >
                  <v-card
                    elevation="0"
                    color="#EEF2FD"
                    @click="goToUpload(index)"
                    :disabled="card.finished"
                  >
                    <v-img
                      max-height="70px"
                      max-width="70px"
                      :src="require(`@/assets/cards/scope/${card.image}`)"
                      class="mb-0"
                    />
                    <p>{{ card.name }}</p>
                  </v-card>
                </v-col>
              </v-row>

              <div
                class="d-flex justify-center mb-5"
                style="width: 100%; gap: 0.5rem"
              >
                <v-btn
                  v-if="anyCardFinished"
                  @click="closeDialog"
                  elevation="0"
                  class="edit-button mt-10 back-button"
                  >Back To structures</v-btn
                >
                <v-btn
                  v-else
                  @click="step = 1"
                  elevation="0"
                  class="edit-button mt-10 back-button"
                  >Back</v-btn
                >
                <v-btn
                  @click="closeDialog"
                  elevation="0"
                  :disabled="!allCardsFinished"
                  class="edit-button mt-10"
                  >Finish</v-btn
                >
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-file-input
                  label="Upload files"
                  accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                  multiple
                  :show-size="3000"
                  @change="validateFile"
                  placeholder="Select your files"
                  :error-messages="errorMessage"
                >
                  <template v-slot:selection="{ file, index }">
                    <v-chip v-if="index < 3" :color="getColor(file.name)" dark>
                      <v-icon left :size="24">
                        {{ getFileIcon(file.name) }}
                      </v-icon>
                      {{ truncateText(file.name, 15) }}
                    </v-chip>
                    <span v-if="index === 3" class="ml-2"
                      >+{{ files.length - 3 }} more</span
                    >
                  </template>
                </v-file-input>
                <v-textarea
                  v-model="comment"
                  :rules="[
                    (v) => !!v || 'Comment is required',
                    (v) => (v && v.length <= 500) || 'Max 500 characters',
                    (v) => (v && v.length >= 10) || 'Min 10 characters',
                  ]"
                  label="Comment"
                  rows="3"
                  outlined
                ></v-textarea>
                <div
                  class="d-flex justify-center mb-5"
                  style="width: 100%; gap: 0.5rem"
                >
                  <v-btn
                    @click="backToStep2"
                    elevation="0"
                    class="edit-button mt-10 back-button"
                    >Back</v-btn
                  >
                  <v-btn
                    @click="uploadFiles"
                    :disabled="!valid"
                    elevation="0"
                    class="edit-button mt-10"
                    >Update</v-btn
                  >
                </div>
              </v-form>
              <v-alert class="mt-3" v-if="error" type="error" dense text
                >An error has occurred, please try again
              </v-alert>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-content>
    </v-card>
  </v-dialog>
</template>

<script>
import scope from "@/api/scope";
export default {
  props: {
    dialog: Boolean,
    structure: Object,
    index: Number,
  },
  data() {
    return {
      localDialog: this.dialog,
      step: 1,
      valid: true,
      error: false,
      files: [],
      errorMessage: "",
      comment: "",
      cards: [
        { name: "Roof", image: "roof.svg", selected: false, finished: false },
        {
          name: "Gutters",
          image: "gutters.svg",
          selected: false,
          finished: false,
        },
        {
          name: "Siding",
          image: "siding.svg",
          selected: false,
          finished: false,
        },
        {
          name: "Fencing",
          image: "fencing.svg",
          selected: false,
          finished: false,
        },
        {
          name: "Custom",
          image: "custom.svg",
          selected: false,
          finished: false,
        },
        {
          name: "Restoration",
          image: "restoration.svg",
          selected: false,
          finished: false,
        },
        // Agrega más tarjetas aquí
      ],
      selectedCards: [],
      currentCardIndex: 0,
    };
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    localDialog(newVal) {
      if (!newVal) {
        this.$emit("update:dialog", false);
      }
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    toggleCardSelection(index) {
      this.cards[index].selected = !this.cards[index].selected;
      if (this.cards[index].selected) {
        this.selectedCards.push(this.cards[index]);
      } else {
        const cardSelected = this.cards[index];
        this.selectedCards = this.selectedCards.filter(
          (card) => card.name !== cardSelected.name,
        );
      }
    },
    goToUpload(index) {
      this.currentCardIndex = index;
      this.step = 3;
    },
    validateFile(files) {
      if (!files.length) {
        this.errorMessage = "No files selected";
        this.valid = false;
      } else if (
        files.every((file) =>
          [".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg"].includes(
            file.name.split(".").pop(),
          ),
        )
      ) {
        this.errorMessage = "Invalid file format";
        this.valid = false;
      } else if (
        !files.every(
          (file) =>
            file.size <=
            parseInt(process.env.VUE_APP_WEIGHT_OF_FILES) * 1024 * 1024,
        )
      ) {
        this.errorMessage = "File size should be less than 10MB";
        this.valid = false;
      } else {
        this.errorMessage = "";
        this.valid = true;
      }
      this.files = files;
    },
    truncateText(text, length) {
      const extension = text.split(".").pop();
      const name = text.substring(0, text.length - extension.length - 1);
      return name.length > length
        ? name.substring(0, length) + "..." + extension
        : text;
    },
    getFileIcon(filename) {
      const extension = filename.split(".").pop().toLowerCase();
      const iconMap = {
        pdf: "mdi-file-document-outline",
        doc: "mdi-file-document-outline",
        docx: "mdi-file-document-outline",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
      };

      return iconMap[extension] || "mdi-file-document-outline";
    },
    getColor(filename) {
      const extension = filename.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "red";
        case "doc":
        case "docx":
          return "blue";
        case "png":
        case "jpg":
        case "jpeg":
          return "green";
        default:
          return "grey";
      }
    },
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("comment", this.comment);
      formData.append("structure_id", this.structure.structure_id);
      formData.append("project_id", this.$route.params.id);
      formData.append(
        "trade_name",
        this.selectedCards[this.currentCardIndex].name,
      );

      if (this.$refs.form.validate()) {
        this.valid = false;
        this.error = false;

        try {
          const response = await scope.createStructureTrade(formData);
          if (response.code == 200) {
            this.$refs.form.reset();
            this.valid = true;
            this.selectedCards[this.currentCardIndex].finished = true;
            this.step = 2;
          } else {
            this.valid = true;
            this.error = true;
          }
        } catch (error) {
          this.valid = true;
          this.error = true;
        }
      }
    },
    backToStep2() {
      this.valid = true;
      this.$refs.form.reset();
      this.step = 2;
    },
  },
  computed: {
    allCardsFinished() {
      return this.selectedCards.every((card) => card.finished);
    },
    anyCardFinished() {
      return this.selectedCards.some((card) => card.finished);
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px !important;
  border: 1px solid #b5c0cc;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-card--link:before {
  background: none !important;
  transition: 0.1s opacity;
}

.step-content {
  width: 98vw;
}

@media (min-width: 768px) {
  .step-content {
    width: 50vw;
  }

  .v-card {
    min-width: 130px !important;
  }
}

@media (min-width: 1200px) {
  .step-content {
    width: 30vw;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  min-width: 155px !important;
  max-width: 45% !important;
  font-size: 15px !important;
  font-family: sans-serif !important;
}

.back-button {
  background-color: #e0e0e0 !important;
  color: black !important;
}
</style>
