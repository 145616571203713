<template>
  <v-container fluid style="height: 100%">
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <header class="d-flex flex-column mt-5 mb-7">
        <p class="mb-2 text-center" style="font-size: 20px">
          {{ $t("projects.cards.internalFile.uploadFiles") }}
        </p>
        <p class="text-center font-weight-regular">
          {{ $t("projects.cards.internalFile.uploadInstructions") }}
        </p>
      </header>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-file-input
          :label="$t('projects.cards.internalFile.uploadLabel')"
          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
          multiple
          :show-size="10000"
          @change="validateFile"
          :placeholder="$t('projects.cards.internalFile.selectFiles')"
          :error-messages="errorMessage"
        >
          <template v-slot:selection="{ file, index }">
            <v-chip v-if="index < 3" :color="getColor(file.name)" dark>
              <v-icon left :size="24">
                {{ getFileIcon(file.name) }}
              </v-icon>
              {{ truncateText(file.name, 15) }}
            </v-chip>
            <span v-if="index === 3" class="ml-2">
              +{{ files.length - 3 }}
              {{ $t("projects.cards.internalFile.more") }}
            </span>
          </template>
        </v-file-input>
        <div
          class="d-flex justify-center mb-5"
          style="width: 100%; gap: 0.5rem"
        >
          <v-btn @click="goBack" class="edit-button mt-10 back-button">
            {{ $t("projects.cards.internalFile.backToOptions") }}
          </v-btn>
          <v-btn
            @click="uploadFiles"
            :disabled="!valid || !files.length"
            class="edit-button mt-10"
          >
            {{ $t("projects.cards.internalFile.confirmInformation") }}
          </v-btn>
        </div>
      </v-form>
      <v-alert class="mt-3" v-if="error" type="error" dense text>
        {{ $t("projects.cards.internalFile.errorOccurred") }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import project from "@/api/project";
import { mapMutations } from "vuex";
export default {
  name: "InternalFile",
  data() {
    return {
      valid: true,
      files: [],
      errorMessage: "",
      error: false,
    };
  },
  methods: {
    ...mapMutations(["setInternalFiles"]),
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });

      formData.append("project_id", this.$route.params.id);

      if (this.$refs.form.validate()) {
        this.valid = false;
        this.error = false;

        try {
          const response = await project.uploadFiles(formData);
          if (response.code == 200) {
            this.valid = true;
            this.$refs.form.reset();
            this.setInternalFiles(true);
            this.goBack();
          } else {
            this.valid = true;
            this.error = true;
          }
        } catch (error) {
          this.valid = true;
          this.error = true;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    validateFile(files) {
      if (!files.length) {
        this.errorMessage = this.$t(
          "projects.cards.internalFile.noFilesSelected",
        );
        this.valid = false;
      } else if (
        files.every((file) =>
          [".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg"].includes(
            file.name.split(".").pop(),
          ),
        )
      ) {
        this.errorMessage = this.$t(
          "projects.cards.internalFile.invalidFormat",
        );
        this.valid = false;
      } else if (
        !files.every(
          (file) =>
            file.size <=
            parseInt(process.env.VUE_APP_WEIGHT_OF_FILES) * 1024 * 1024,
        )
      ) {
        // 10 MB
        this.errorMessage = this.$t(
          "projects.cards.internalFile.fileSizeExceeded",
        );
        this.valid = false;
      } else {
        this.errorMessage = "";
        this.valid = true;
      }
      this.files = files;
    },
    truncateText(text, length) {
      const extension = text.split(".").pop();
      const name = text.substring(0, text.length - extension.length - 1);
      return name.length > length
        ? name.substring(0, length) + "..." + extension
        : text;
    },
    getFileIcon(filename) {
      const extension = filename.split(".").pop().toLowerCase();
      const iconMap = {
        pdf: "mdi-file-document-outline",
        doc: "mdi-file-document-outline",
        docx: "mdi-file-document-outline",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
      };

      return iconMap[extension] || "mdi-file-document-outline";
    },
    getColor(filename) {
      const extension = filename.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "red";
        case "doc":
        case "docx":
          return "blue";
        case "png":
        case "jpg":
        case "jpeg":
          return "green";
        default:
          return "grey";
      }
    },
  },
};
</script>

<style scoped>
header,
form {
  width: 95%;
}

form {
  border: 1px dashed #ccc;
  padding: 5rem 3rem;
}

@media (min-width: 768px) {
  header,
  form {
    width: 50%;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  min-width: 175px !important;
  max-width: 48% !important;
  font-size: 15px !important;
  font-family: sans-serif !important;
}

.back-button {
  background-color: #e0e0e0 !important;
  color: black !important;
}
</style>
